.container-publishMessage {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  font-size: 14px;
  color: #333;
}

.container-publishMessage > header {
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  padding: 24px 20%;
  border-bottom: 1px solid #F1F1F1;
  box-sizing: border-box;
}

.container-publishMessage > header > img {
  width: 140px;
  height: auto;
  display: block;
  margin-right: 24px;
}

.container-publishMessage > section {
  padding: 24px 20%;
  box-sizing: border-box;
}

.container-publishMessage > section .sect-title {
  background: #F9F9F9;
  border-radius: 8px;
  padding: 6px 24px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}

.container-publishMessage > section .sect-tabs {
  border-bottom: 1px solid #F1F1F1;
  margin-bottom: 24px;
}

.form-name {
  width: 50%;
}

.form-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.form-content > div {
  width: 30%;
  display: flex;
  align-items: flex-end;
}

.form-content > div > .zhi {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  padding-top: 10px;
  box-sizing: border-box;
  color: #999999;
}

.ant-form-item {
  width: 100%;
}

.form-btn {
  text-align: center;
}

.form-btn > button {
  width: 400px;
  height: 40px;
  background: #32B499;
  border-radius: 8px 8px 8px 8px;
}