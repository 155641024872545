
.container-login {
  width: 100%;
  height: 100vh;
  color: #333;
}

.container-login > img {
  width: 100%;
  height: 100%;
  display: block;
}

.login-logo {
  width: 500px;
  position: fixed;
  z-index: 100;
  top: 40px;
  right: 170px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}

.login-logo img {
  width: 160px;
  height: auto;
  display: block;
}

.login-form {
  width: 500px;
  height: 354px;
  background: #FFFFFF;
  box-shadow: 4px 4px 6px 0px rgba(0,0,0,0.05), -4px -4px 6px 0px rgba(0,0,0,0.05);
  border-radius: 16px;
  position: fixed;
  right: 170px;
  top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  box-sizing: border-box;
}

.login-info {
  width: 100%;
  box-sizing: border-box;
}

.login-info button {
  width: 100%;
  height: 35px;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 0;
}

.ant-spin .ant-spin-dot-item {
  background-color: #32B499 !important;
}

.login-filing-number {
  width: 100%;
  position: fixed;
  bottom: 24px;
  left: 0;
}

.login-btns {
  display: flex;
  align-items: flex-end;
}

.login-btns .loginBtn {
  margin-top: 0;
}

.login-btns > div:last-child {
  width: 60px;
  text-align: center;
  text-decoration: underline;
}