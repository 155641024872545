body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #32B499;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-12upa3x).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #32B499;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #32B499;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-checked:after {
  border-color: #32B499;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #32B499;
}