.container-userInfo {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.container-userInfo > header {
  display: flex;
  justify-content: space-between;
  padding: 24px 20%;
  border-bottom: 1px solid #F1F1F1;
  box-sizing: border-box;
  font-size: 16px;
}

.container-userInfo > header > div {
  display: flex;
  align-items: flex-end;
}

.container-userInfo > header > div:first-child {
  font-size: 20px;
}

.container-userInfo > header > div:first-child > div {
  border-left: 1px solid #F1F1F1;
  padding-left: 24px;
  box-sizing: border-box;
}

.container-userInfo > header > div:last-child > div:first-child {
  border-right: 1px solid #F1F1F1;
  padding-right: 24px;
  box-sizing: border-box;
}

.container-userInfo > header > div:last-child > div:last-child {
  padding-left: 24px;
  box-sizing: border-box;
}

.container-userInfo > header img {
  width: 140px;
  height: auto;
  display: block;
  margin-right: 24px;
}

.container-userInfo > section {
  flex: 1;
  padding: 24px 20%;
  box-sizing: border-box;
  display: flex;
}

.container-userInfo > section .sect-left {
  width: 20%;
}

.container-userInfo > section .user-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 24px;
}

.container-userInfo > section .user-content img {
  width: 50px;
  height: auto;
  display: block;
  margin-bottom: 12px;
}

.container-userInfo > section .sect-right {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  box-sizing: border-box;
}

.container-userInfo > section .sect-right .user-form {
  width: 300px;
}

.user-form-avater {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #F1F1F1;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.user-form-avater img {
  width: 40px;
  height: auto;
  display: block;
  margin-right: 24px;
}

.container-userInfo > section .sect-right .user-form button {
  width: 100%;
  height: 40px;
  margin: 0;
}

.container-userInfo > section .sect-right .release-list {
  padding: 0 24px;
  box-sizing: border-box;
}

.container-userInfo > section .sect-right .release-tabs {
  background: #F9F9F9;
  border-radius: 8px 8px 8px 8px;
  padding: 3px 12px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.container-userInfo > section .sect-right .release-tabs .ant-tabs-nav {
  margin: 0;
}

.release-goodsList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.release-goodsList .goods-item {
  width: 18.5%;
  box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.12);
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 12px;
  margin-right: 16px;
}

.release-goodsList .goods-item:nth-child(5n) {
  margin-right: 0;
}

.release-goodsList .goods-item > img {
  width: 100%;
  height: 140px;
  display: block;
  border-radius: 8px;
}

.release-goodsList .goods-item .goods-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 1;  /* 设置超过为省略号的行数 */
  overflow: hidden;
  padding: 0 12px;
  box-sizing: border-box;
  margin-top: 12px;
}

.release-goodsList .goods-item .goods-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
}

.release-goodsList .goods-item .goods-info > div:last-child {
  width: 70px;
  height: 30px;
  background: #32B499;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}