.container-home {
  width: 100%;
  height: 100vh;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  overflow-y: auto;
  padding-bottom: 24px;
  box-sizing: border-box;
  color: #333333;
}

.container-home > header {
  width: 100%;
  height: auto;
  padding: 20px 20% 0;
  box-sizing: border-box;
  background-color: #fff;
}

.container-home > header > .head-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-home > header .head-top img {
  width: 110px;
  height: auto;
  display: block;
}

.container-home > header .head-top > div:last-child {
  flex: 1;
  padding-left: 20%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-home > header .head-top > div:last-child .search {
  width: 550px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #CDCDCD;
  border-radius: 20px;
  padding: 0 12px;
  box-sizing: border-box;
}

.container-home > header .head-top > div:last-child > div:nth-child(2) {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  background-color: #32B499;
  color: #fff;
  border-radius: 40px;
}

.container-home > header .head-top > div:last-child > div:nth-child(2) > span:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-home > header .head-top > div:last-child > div:nth-child(2) > span:last-child {
  margin-left: 5px;
}

.container-home > header .head-top > div:last-child .loginInfo > .unLogin {
  display: flex;
  color: #666;
}

.container-home > header .head-top > div:last-child .loginInfo .unLogin > div:first-child {
  margin-right: 20px;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-tabs-top >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs-bottom >.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs-top >div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs-bottom >div>.ant-tabs-nav::before {
  border: 0;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #32B499;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-ink-bar {
  background: #32B499;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible), :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab-btn:active, :where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab-remove:active {
  color: #32B499 !important;
}

.ant-tabs-tab-btn:hover {
  color: #32B499 !important;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-tabs .ant-tabs-tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-home > section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.container-home > section .sectBox-banner > img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 12px;
}

.container-home > section .sectBox-content {
  padding: 0 20%;
  box-sizing: border-box;
}

.sectBox-content .sect-list {
  width: 100%;
  padding: 12px;
  padding-right: 0;
  background: #FFFFFF;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.sectBox-content .sect-list-head {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32B499;
  font-size: 20px;
  margin-bottom: 24px;
}

.sectBox-content .sect-list-head img {
  width: 35px;
  height: auto;
  display: block;
}

.sectBox-content .sect-list-head span {
  margin: 0 10px;
}

.sectBox-content .sect-list-goods {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.sectBox-content .sect-list-goods .goods-item {
  width: 24%;
  box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.12);
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 12px;
  margin-right: 10px;
}

.sectBox-content .sect-list-goods .goods-item > img {
  width: 100%;
  height: 270px;
  display: block;
  border-radius: 8px;
}

.sectBox-content .sect-list-goods .goods-item .goods-name {
  padding: 0 12px;
  margin-top: 10px;
  box-sizing: border-box;
}

.sectBox-content .sect-list-goods .goods-item .goods-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
}

.price {
  color: #EA5F5F;
  display: flex;
  align-items: flex-end;
}

.price span {
  font-size: 20px;
}

.sectBox-content .sect-list-goods .goods-item .goods-info > div:last-child {
  width: 90px;
  height: 30px;
  background: #32B499;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}


.container-home > section .sect-goodsList {
  flex: 1;
  padding: 12px 20%;
  box-sizing: border-box;
}

.container-home > section .sect-goodsList .goodsList {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
}

.container-home > section .sect-goodsList .goodsList .goodsList-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
}

.container-home > section .sect-goodsList .goodsList .goodsList-item > img {
  width: 165px;
  height: 165px;
  border-radius: 8px;
  display: block;
}

.goodsList .goodsList-item > .goodsList-item-content {
  flex: 1;
  height: 165px;
  padding-left: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-name {
  font-size: 16px;
  font-weight: bold;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-coordinate {
  color: #999;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-coordinate >span:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-time {
  color: #999;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-look {
  display: flex;
  justify-content: flex-end;
}

.goodsList .goodsList-item > .goodsList-item-content .goods-look > div {
  width: 90px;
  height: 30px;
  background: #32B499;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


.goods-detail {
  padding: 12px 20%;
  box-sizing: border-box;
}

.goods-detail .detail-card {
  display: flex;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
}

.goods-detail .detail-card > img {
  width: 271px;
  height: 271px;
  display: block;
  border-radius: 8px;
}

.goods-detail .detail-card > .card-content {
  flex: 1;
  height: 271px;
  padding-left: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.goods-detail .detail-card > .card-content .card-name {
  font-size: 16px;
  font-weight: bold;
}

.goods-detail .detail-card > .card-content .card-userInfo {
  display: flex;
  align-items: center;
}

.goods-detail .detail-card > .card-content .card-userInfo > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.goods-detail .detail-card > .card-content .card-userInfo > div:first-child {
  width: 130px;
  height: 36px;
  background: #32B499;
  color: #fff;
  margin-right: 12px;
}

.goods-detail .detail-card > .card-content .card-userInfo > div:last-child {
  width: 160px;
  height: 36px;
  background: #FFDADA;
  color: #EA5F5F;
}

.goods-detail .detail-card > .card-content .card-userInfo > div:last-child > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-info {
  display: flex;
  align-items: flex-start;
}

.detail-info > div > header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-info > div > header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #32B499;
  font-size: 16px;
  margin-bottom: 16px;
}

.detail-info > div > header img {
  width: 25px;
  height: auto;
  display: block;
}

.detail-info > div > header span {
  margin: 0 10px;
}

.detail-info > div {
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

.detail-info > .detailInfo-left {
  flex: 1;
  background-color: #fff;
  /* margin-right: 24px; */
}

.detail-info > .detailInfo-left > div > img {
  width: 100%;
  height: auto;
}

.detail-info > .detailInfo-right {
  width: 300px;
  background-color: #fff;
}

.detail-info .detailInfo-goods {
  margin-bottom: 24px;
}

.detail-info .detailInfo-goods > img {
  width: 100%;
  height: 278px;
  display: block;
  border-radius: 8px;
  margin-bottom: 10px;
}

.detail-info .detailInfo-goods > div {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  -webkit-line-clamp: 2;  /* 设置超过为省略号的行数 */
  overflow: hidden;
}

.ant-btn-primary {
  margin-left: 12px;
  background-color: #32B499;
  color: #fff;
}

.ant-btn-primary:hover {
  background-color: #32B499 !important;
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-item-selected, :where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: #32B499;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light .ant-menu-item-selected {
  background-color: none;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: 0;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #32B499;
  background-color: #32B499;
}

:where(.css-1vr7spz).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #32B499;
}

:where(.css-1vr7spz).ant-tabs .ant-tabs-ink-bar {
  background-color: #32B499;
}

.filing-number {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filing-number > div:first-child {
  margin-right: 10px;
}